import React, {useState} from 'react';

import {Button, Header, Label, Message} from 'semantic-ui-react';
import {Form} from 'formsy-semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';
import useFormSubmission from '../../hooks/use-form-submission';
import BotCheck from '../../components/site/elements/BotCheck';
import logger from '../../logger';
import Page from '../../components/site/layouts/Page';
import useSiteSettings from '../../hooks/use-site-settings';
import SEO from '../../components/shared/SEO/SEO';
import PageTitle from '../../components/site/PageTitle';
import PageSubtitle from '../../components/site/PageSubtitle';

const WhatIsMyHomeWorth = () => {
  const siteSettings = useSiteSettings();
  const [botcheckResponse, setBotcheckResponse] = useState(null);
  const {
    submitForm,
    formIsSubmitting,
    formSuccess,
    formErrors,
  } = useFormSubmission({campaign: 'What is my home worth'});
  const [message, setMessage] = useState(null);

  const validated = !!botcheckResponse;
  const isPosted = !!formSuccess;

  const onCaptchaLoaded = () => {
    logger.debug('Bot check engaged');
  };
  const onCaptchaValidated = setBotcheckResponse;
  const onCaptchaExpired = () => setBotcheckResponse(null);
  const onValidSubmit = formData => {
    // logger.debug(formData);
    const {name, email, phone, street, city, state, zip} = formData;
    const submission = {
      messageType: 'home_value',
      name,
      email,
      phone,
      botcheckResponse,
      details: {
        message,
        property_info: {street, city, state, zip},
      },
    };

    return submitForm(submission);
  };

  return (
    <Page>
      <SEO
        title="What is my home worth in North, Central, South Austin"
        description="How much can I sell my home for in Austin?"
        pathname="what-is-my-home-worth/"
        keywords={[
          'Contact a Realtor',
          'Angie Ufomata Realtor',
          'North Austin',
          'South Austin',
          'Central Austin',
        ]}
      />

      <PageTitle as="h1">
        Get a Free Home Valuation
        <PageSubtitle>Complete the info below</PageSubtitle>
      </PageTitle>

      <Form
        onValidSubmit={onValidSubmit}
        loading={formIsSubmitting}
        error={!!formErrors}>
        <Header as="h3">Property Info</Header>
        <Form.Input
          required
          name="street"
          label="Street Address"
          placeholder="Street Address"
          errorLabel={<Label color="red" pointing />}
          validationErrors={{
            isDefaultRequiredValue: 'Street is required',
          }}
        />
        <Form.Group widths="equal">
          <Form.Input
            required
            name="city"
            label="City"
            placeholder="City"
            validations="isWords"
            errorLabel={<Label color="red" pointing />}
            validationErrors={{
              isWords: 'No numbers or special characters allowed',
              isDefaultRequiredValue: 'City is required',
            }}
          />
          <Form.Input
            required
            name="state"
            label="State"
            placeholder="State"
            validations="isWords"
            errorLabel={<Label color="red" pointing />}
            validationErrors={{
              isWords: 'No numbers or special characters allowed',
              isDefaultRequiredValue: 'State is required',
            }}
            value="TX"
          />
          <Form.Input
            required
            name="zip"
            label="Zip code"
            placeholder="Zip code"
            errorLabel={<Label color="red" pointing />}
            validationErrors={{
              isDefaultRequiredValue: 'Zip code is required',
            }}
          />
        </Form.Group>

        <Header as="h3">
          Where would you like me to send your home valuation report?
        </Header>
        <Form.Input
          required
          name="name"
          label="Name"
          placeholder="Name"
          validations="isWords"
          errorLabel={<Label color="red" pointing />}
          validationErrors={{
            isWords: 'No numbers or special characters allowed',
            isDefaultRequiredValue: 'Name is required',
          }}
        />

        <Form.Group widths="equal">
          <Form.Input
            required
            name="email"
            label="Email"
            placeholder="Email"
            validations="isEmail"
            errorLabel={<Label color="red" pointing />}
            validationErrors={{
              isEmail: 'Please enter a valid email',
              isDefaultRequiredValue: 'Email is required',
            }}
          />
          <Form.Input
            required
            name="phone"
            label="Phone"
            placeholder="Phone"
            validations={{
              matchRegexp: /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s-]?[\0-9]{3}[\s-]?[0-9]{4}$/,
            }}
            errorLabel={<Label color="red" pointing />}
            validationErrors={{
              matchRegexp: 'Please enter a valid phone number',
              isDefaultRequiredValue: 'Phone is required',
            }}
          />
        </Form.Group>

        <Header as="h3">Anything else you&apos;d like to share?</Header>
        <Form.Field
          control={TextareaAutosize}
          minRows={3}
          name="message"
          placeholder="Tell me more"
          onChange={ev => setMessage(ev.target.value)}
        />

        <BotCheck
          onLoad={onCaptchaLoaded}
          onValidated={onCaptchaValidated}
          onExpired={onCaptchaExpired}
        />

        <Button
          primary
          fluid
          className="large-tm"
          type="submit"
          disabled={validated && isPosted}>
          Send
        </Button>

        {formErrors && (
          <Message
            error
            header="There were some errors with your submission"
            list={formErrors}
          />
        )}
      </Form>

      {isPosted && (
        <Message
          success
          header={`Got your message ${formSuccess.name.split(' ')[0]}`}
          content={`I will be in contact soon. Feel free to reach me at ${siteSettings.phone}. Text messages work great. Chat soon.`}
        />
      )}
    </Page>
  );
};

export default WhatIsMyHomeWorth;
